<template>
  <div class="content">
      <div class='main'>
          <div class="utilities_nav">
            <span class="nav_list" v-for="item in navList" :key="item.id"  
            :class="{ active: item.id == activeId }" @click="toggleRoute(item.id, item.route)">
              <span class="icon">
                <img :src="item.url" alt="" style='transform: translateY(4px);'>
              </span>
              <span slot="title">{{item.name}}</span>
            </span>
          </div>
          <div style="background-color: white;">
            <router-view ></router-view>
          </div>
    </div>
  </div>
</template>

<script>
import printRecordSearch from './printRecordSearch.vue'
export default {
  components:{
    printRecordSearch
  },
    data(){
      return {
        activeId:1,
        navList: [
        {
          id: 1,
          name: "打印记录",
          route: "/utilities/printRecordSearch",
          url:'http://cdnprinttool.xingyebao.com/ksPrintTool/images/uncommon1.png'
        },
        {
          id: 2,
          name: "发货记录",
          route: "/utilities/deliverGoodsRecord",
          url:'http://cdnprinttool.xingyebao.com/ksPrintTool/images/uncommon2.png'
        },
       
      ],
      }
    },
    methods:{
        toggleRoute(id, route){
          this.activeId = id;
          this.$router.push({path:route})
        }
    },
    mounted() {
      this.activeId = window.sessionStorage.getItem("activeSId");
  },
  created() {
    //  this.$router.push({path:'/utilities/printRecordSearch'})
  },
   
}
</script>

<style  scoped>
  .content {
    padding: 10px 10px 0px;
    background: linear-gradient(#fafafa, #ffffff);
    /* background: #fafafa; */
  }
  .main {
    border-radius:10px;
  }
  .utilities_nav {
    background-color: #fff;
    margin-bottom: 10px;
  }
  .nav_list {
      display: inline-block;
      padding: 10px;
      cursor: pointer;
      margin-right: 10px;
    }
  .active {
    color: #1f6eff;
    background-color: #eff2f9;
   
  }
  .icon {
    margin-right: 5px;
    width: 25px;
    height: 25px;
  
  }

</style>